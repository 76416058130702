import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { DEFAULT_PAGE, DEFAULT_TOTAL_PAGES, PathnameUrl } from "@app/constants";
import AccountService from "@app/services/http/account.service";
import { Account } from "@app/types/account.type";
import { formatDate, formatMoneyVN } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BetStatus, QueryParams } from "@app/types/bank.type";
import { debounceTime, Subject } from "rxjs";
import useForceUpdate from "@core/hooks/use-force-update.hook";

function Accounts() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [search, setSearch] = useState<QueryParams>({
    uid: "",
    phone: "",
    bet_state: "",
  });
  const navigate = useNavigate();
  const searchSubject = new Subject<{
    key: keyof QueryParams;
    value: string;
  }>();

  const [update, forceUpdate] = useForceUpdate();
  const { subscribeUntilDestroy, subscribeOnce } = useObservable();

  useEffect(() => {
    searchSubject.pipe(debounceTime(300)).subscribe(({ key, value }) => {
      setSearch((prev) => ({ ...prev, [key]: value }));
    });
  }, [searchSubject]);

  useEffect(() => {
    subscribeUntilDestroy(AccountService.get(page, search), (res) => {
      if (res.data.length) {
        setAccounts(res.data);
        setTotalPages(res.total_page);
      } else {
        setAccounts([]);
        setTotalPages(DEFAULT_TOTAL_PAGES);
      }
    });
  }, [page, search, update]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onFilterChange = (key: keyof QueryParams, value: string) => {
    searchSubject.next({
      value,
      key,
    });
  };

  const handleShowDetailClick = (account: Account) => {
    navigate(`${PathnameUrl.ACCOUNT_MANAGEMENT}/${account._id}`);
  };

  const handleGetTitle = (status: BetStatus) => {
    switch (status) {
      case BetStatus.WIN:
        return "Thắng";
      case BetStatus.LOSS:
        return "Thua";
      case BetStatus.RANDOM:
        return "Ngẫu nhiên";
      default:
        return status;
    }
  };

  const onUpdateBetState = (account_id: string, bet_state: BetStatus) => {
    subscribeOnce(AccountService.updateBetState(account_id, bet_state), () => {
      forceUpdate();
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <h1 className="text-3xl font-bold ml-4 mb-6">Quản lý tài khoản</h1>
      <div className="pb-2 px-2]">
        <div className="flex gap-4">
          <div className="ml-2 flex gap-2 items-center">
            <label
              htmlFor="uid"
              className="block text-sm font-medium text-gray-900 dark:text-white"
            >
              UID
            </label>
            <Input
              id="uid"
              className="mt-0 ml-2 max-w-[200px]"
              placeholder="UID..."
              onChange={(e) => onFilterChange("uid", e.target.value)}
              value={search.uid}
            />
          </div>
          <div className="ml-2 flex gap-2 items-center">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-900 dark:text-white"
            >
              Số điện thoại
            </label>
            <Input
              id="phone"
              className="mt-0 ml-2 max-w-[200px]"
              placeholder="Số điện thoại..."
              onChange={(e) => onFilterChange("phone", e.target.value)}
              value={search.phone}
            />
          </div>
          <div className="ml-2 flex gap-2 items-center">
            <label
              htmlFor="betState"
              className="block text-sm font-medium text-gray-900 dark:text-white min-w-[70px]"
            >
              Trạng thái
            </label>
            <select
              id="betState"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => onFilterChange("bet_state", e.target.value)}
            >
              <option selected value="">
                Trạng thái
              </option>
              {Object.values(BetStatus).map((value) => (
                <option key={value} value={value}>
                  {handleGetTitle(value)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="px-2 overflow-x-auto mt-2">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead className="text-white">
                <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                  {/* <th className="p-3 text-left w-32">ID</th> */}
                  <th className="p-3 text-left w-32">ID</th>
                  {/* <th className="p-3 text-left w-96">Tên</th> */}
                  <th className="p-3 text-left w-40">SĐT</th>
                  <th className="p-3 text-left w-40">TK G.Ngay</th>
                  <th className="p-3 text-left w-40">Ví</th>
                  <th className="p-3 text-left w-40">Tổng Số Dư</th>
                  <th className="p-3 text-left w-40">Ngày đăng ký</th>
                  <th className="p-3 text-left w-40">Trạng Thái</th>
                  <th className="p-3 text-center w-40"></th>
                </tr>
              </thead>
              <tbody>
                {!!accounts.length &&
                  accounts.map((account) => (
                    <tr
                      key={account._id}
                      className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                    >
                      {/* <td className="p-3">{account._id}</td> */}
                      <td className="p-3">{account.uid}</td>
                      {/* <td className="p-3 whitespace-pre-line">
                        {account.nick_name}
                      </td> */}
                      <td className="p-3">{account.phone}</td>
                      <td className="p-3">
                        {formatMoneyVN(account.balance)} USDT
                      </td>
                      <td className="p-3">
                        {formatMoneyVN(account.balance_wallet)} USDT
                      </td>
                      <td className="p-3 text-green-500">
                        {formatMoneyVN(
                          account.balance_wallet + account.balance
                        )}{" "}
                        USDT
                      </td>
                      {/* <td className="p-3">
                        {account.role === "user"
                          ? "Khách hàng"
                          : "Người quản trị"}
                      </td> */}
                      <td className="p-3">{formatDate(account.createdAt)}</td>
                      <td>
                        <select
                          id="betState"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          onChange={(e) =>
                            onUpdateBetState(
                              account._id,
                              e.target.value as BetStatus
                            )
                          }
                        >
                          <option selected value="">
                            {handleGetTitle(account.bet_state)}
                          </option>
                          {Object.values(BetStatus).map((value) => (
                            <option key={value} value={value}>
                              {handleGetTitle(value)}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="p-3 text-center w-40">
                        <MdOutlineRemoveRedEye
                          className="text-[20px] inline-block cursor-pointer"
                          onClick={() => handleShowDetailClick(account)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {!accounts.length && (
              <div className="flex justify-center">Không có dữ liệu</div>
            )}
          </div>
        </div>
        {totalPages > 0 && (
          <div className="flex justify-center mt-3">
            <Pagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Accounts;
