import { PathnameUrl } from "@app/constants";
import AccountService from "@app/services/http/account.service";
import { Account } from "@app/types/account.type";
import { formatDate, formatMoneyVN } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Environment } from "@environments/environment";
import HttpService from "@core/services/http/http.service";
import Button from "@app/components/button";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import Input from "@app/components/input";
import { Form, Formik } from "formik";
import { FormControl } from "@app/components/form-control";
import { addToast } from "@app/components/toast/toast.service";

function AccountDetail() {
  const { id } = useParams();
  const [account, setAccount] = useState<Account>();
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();

  const { subscribeUntilDestroy } = useObservable();
  const [update, forceUpdate] = useForceUpdate();

  const token = HttpService.getAccessToken();

  useEffect(() => {
    if (id) {
      subscribeUntilDestroy(AccountService.getById(id), (res) => {
        if (res) {
          setAccount(res);
        } else {
          navigate(PathnameUrl.ACCOUNT_MANAGEMENT);
        }
      });
    }
  }, [update]);

  const onClickAcceptKyc = (account_id: string, is_kyc: boolean) => {
    subscribeUntilDestroy(
      AccountService.updateAccountById(account_id, { is_kyc }),
      () => {
        forceUpdate();
      }
    );
  };

  const handleUpdateBalance = (value: {
    balance: number | string;
    account_id: string;
  }) => {
    subscribeUntilDestroy(
      AccountService.updateAccountById(value.account_id, {
        balance: +value.balance,
      }),
      () => {
        addToast({ text: "Cập nhật số dư thành công" });
        forceUpdate();
      }
    );
  };

  const deleteAccount = (id: string) => {
    subscribeUntilDestroy(AccountService.deleteAccountById(id), () => {
      forceUpdate();
    });
  };

  const handleUpdatePassword = (value: {
    password: string;
    account_id: string;
  }) => {
    subscribeUntilDestroy(
      AccountService.updateAccountById(value.account_id, {
        password: value.password,
      }),
      () => {
        addToast({ text: "Cập nhật mật khẩu thành công" });
        forceUpdate();
      }
    );
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="flex gap-2 items-center text-3xl font-bold ml-4 mb-6">
        <i
          className="fa-solid fa-chevron-left cursor-pointer"
          onClick={() => navigate(-1)}
        ></i>
        Chi tiết tài khoản
      </div>
      {account && (
        <div className="w-full flex ml-4 gap-4">
          <div className="w-[300px] flex flex-col items-center border-r-2 border-r-green-500">
            <img
              src={account?.avt}
              alt=""
              className="rounded-full w-[150px] h-[150px] mb-4"
            />
            <p className="text-center">{account?.nick_name}</p>
          </div>
          <div className="w-full">
            <div className="flex gap-2 mb-2">
              <b>UID: </b>
              <p>{account?.uid}</p>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Số điện thoại: </b>
              <p>{account?.phone}</p>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Nickname: </b>
              <p>
                {account?.nick_name || (
                  <span className="text-red-500">Chưa có</span>
                )}
              </p>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Họ và tên: </b>
              <p>
                {account?.full_name || (
                  <span className="text-red-500">Chưa có</span>
                )}
              </p>
            </div>
            <div className="mb-2">
              <div className="flex gap-2">
                <b>Số dư tài khoản: </b>
                <p>
                  {(balance && formatMoneyVN(balance)) ||
                    (account?.balance && formatMoneyVN(account?.balance))}{" "}
                  USDT
                </p>
              </div>
              <Formik
                initialValues={{
                  balance: account.balance.toFixed(2),
                  account_id: account._id,
                }}
                onSubmit={handleUpdateBalance}
              >
                <Form className="flex gap-2 items-center w-full">
                  <FormControl name="balance">
                    <Input
                      placeholder="Cập nhật số dư"
                      type="text"
                      onChange={(e) => setBalance(+e.target.value)}
                    />
                  </FormControl>
                  <Button type="submit" label="Cập nhật" />
                </Form>
              </Formik>

              {/* reset mật khẩu  */}
              <Formik
                initialValues={{
                  password: "",
                  account_id: account._id,
                }}
                onSubmit={handleUpdatePassword}
              >
                <Form className="flex gap-2 items-center w-full mt-2">
                  <FormControl name="password">
                    <Input placeholder="Reset mật khẩu" type="text" />
                  </FormControl>
                  <Button type="submit" label="Cập nhật" />
                </Form>
              </Formik>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Trạng thái: </b>
              <p>{account?.bet_state}</p>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Ngân hàng: </b>
              <p>
                {account?.bank_name || (
                  <span className="text-red-500">Chưa có</span>
                )}
              </p>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Số tài khoản: </b>
              <p>
                {account?.bank_number || (
                  <span className="text-red-500">Chưa có</span>
                )}
              </p>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Chủ tài khoản: </b>
              <p>
                {account?.bank_owner || (
                  <span className="text-red-500">Chưa có</span>
                )}
              </p>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Ngày đăng ký: </b>
              <p>{account?.createdAt && formatDate(account?.createdAt)}</p>
            </div>
            <div className="flex gap-2 mb-2">
              <b>Xác minh danh tính: </b>
              {account?.is_kyc ? (
                <p className="text-green-500">Đã xác minh</p>
              ) : (
                <p className="text-red-500">Chưa xác minh</p>
              )}
            </div>
            <div className="">
              <h1 className="font-bold">Ảnh yêu cầu xác minh: </h1>
              {!account?.front_card ? (
                <span className="text-red-500">Chưa up</span>
              ) : (
                ""
              )}
              <div className="flex gap-2">
                <img
                  className="w-52"
                  src={
                    Environment.BASE_API +
                    "/images/" +
                    account?.front_card +
                    "?token=" +
                    token
                  }
                  alt=""
                />
                <img
                  className="w-52"
                  src={
                    Environment.BASE_API +
                    "/images/" +
                    account?.back_card +
                    "?token=" +
                    token
                  }
                  alt=""
                />
              </div>
            </div>
            <div>
              {account?.is_kyc ? (
                <Button
                  size="m"
                  label="Hủy Xác Minh"
                  containerClassName="mt-6"
                  width="200px"
                  className="px-6 text-sm bg-[#fcd535]"
                  labelClassName="font-bold"
                  onClick={() => onClickAcceptKyc(account._id, false)}
                />
              ) : (
                <Button
                  size="m"
                  label="Duyệt Xác Minh"
                  containerClassName="mt-6"
                  width="200px"
                  className="px-6 text-sm bg-[#fcd535]"
                  labelClassName="font-bold"
                  onClick={() => onClickAcceptKyc(account?._id || "", true)}
                />
              )}

              <Button
                size="m"
                label="Xóa tài khoản"
                containerClassName="mt-6"
                width="200px"
                className="px-6 text-sm bg-red-500"
                labelClassName="font-bold"
                onClick={() => deleteAccount(account?._id)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountDetail;
